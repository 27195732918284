import { useState, useEffect } from 'react';

type NotificationPermissionState = 'granted' | 'denied' | 'default';

const useNotification = () => {
  const [permission, setPermission] = useState<NotificationPermissionState>(
    typeof Notification !== 'undefined' ? Notification.permission : 'default'
  );

  // Request notification permission from the user
  const requestPermission = () => {
    if (
      typeof Notification !== 'undefined' &&
      Notification.permission === 'default'
    ) {
      Notification.requestPermission().then(newPermission => {
        setPermission(newPermission);
      });
    } else if (typeof Notification === 'undefined') {
      console.warn('Notifications are not supported in this browser.');
    }
  };

  // Function to send a notification if the window is not active (not in focus)
  const sendNotification = (
    title: string,
    options?: NotificationOptions,
    callback?: () => void
  ) => {
    if (typeof Notification === 'undefined') {
      console.warn('Notifications are not supported in this browser.');
      return;
    }

    if (permission === 'granted' && document.hidden) {
      const notification = new Notification(title, options);

      // Add an onclick event to focus on the tab when notification is clicked
      notification.onclick = () => {
        window.focus(); // Focus on the current tab
        notification.close(); // Close the notification
        if (callback) {
          callback?.();
        }
      };
    } else if (!document.hidden) {
      console.log('The window is currently active, no notification sent.');
    } else {
      console.log('Notification permission is not granted.');
    }
  };

  // Check for permission change
  useEffect(() => {
    if (typeof Notification !== 'undefined') {
      setPermission(Notification.permission);
    } else {
      console.warn('Notifications are not supported in this browser.');
    }
  }, []);

  return { permission, requestPermission, sendNotification };
};

export default useNotification;
